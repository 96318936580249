import { appSlice } from 'slices/appSlice';
import { eventsSlice } from 'slices/eventsSlice';
import { kedoSlice } from 'slices/kedo';
import { newsSlice } from 'slices/newsSlice';
import { promocodesSlice } from 'slices/promocodesSlice';
import { employeeSlice } from 'store/slices/employeeSlice';
import { guideSlice } from 'store/slices/guideSlice';

import { communityCommentsSlice } from './communityCommentsSlice';
import { communityDiscussionsSlice } from './communityDiscussionsSlice';
import { communitiesSlice } from './communitySlice';
import { downloadErrorModalSlice } from './downloadErrorModalSlice';
import { guideSettlementPostersSlice } from './guideSettlementPostersSlice';
import { secureStorageSlice } from './secureStorageSlice';
import { surveySlice } from './surveySlice';

export const slices = {
  [appSlice.name]: appSlice.reducer,
  [employeeSlice.name]: employeeSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
  [newsSlice.name]: newsSlice.reducer,
  [secureStorageSlice.name]: secureStorageSlice.reducer,
  [surveySlice.name]: surveySlice.reducer,
  [guideSlice.name]: guideSlice.reducer,
  [guideSettlementPostersSlice.name]: guideSettlementPostersSlice.reducer,
  [kedoSlice.name]: kedoSlice.reducer,
  [promocodesSlice.name]: promocodesSlice.reducer,
  [downloadErrorModalSlice.name]: downloadErrorModalSlice.reducer,
  [communitiesSlice.name]: communitiesSlice.reducer,
  [communityDiscussionsSlice.name]: communityDiscussionsSlice.reducer,
  [communityCommentsSlice.name]: communityCommentsSlice.reducer,
};
